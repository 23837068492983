import React, { useState, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import { LanguageContext } from "../../../context/language";
import './index.css';
const banner = {
  animate: {
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.1,
    },
  },
};

const letterAni = {
  initial: { y: 400 },
  animate: {
    y: 0,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 1,
    },
  },
};

const letterStyle = "text-[4rem] sm:text-[6rem] md:text-[10rem] xl:text-[12rem] xxl:text-[14rem]";

const Banner = () => {
  const [playMarquee, setPlayMarquee] = useState(true);
  const { languageFile } = useContext(LanguageContext);

  useEffect(() => {
    setTimeout(() => {
      setPlayMarquee(true);
    }, 2000);
  }, []);
  return (
    <motion.div className="banner h-fit" variants={banner}>
      <BannerRowTop title={"software"} />
      <BannerRowCenter title={"developer"} playMarquee={playMarquee} />
      <BannerRowBottom title={"collective"} scroll1={languageFile.hero.scroll1} scroll2={languageFile.hero.scroll2} />
    </motion.div>
  );
};

const AnimatedLetters = ({
  title,
  disabled,
}: {
  title: string;
  disabled?: boolean;
}) => (
  <motion.span
    className={`row-title ${letterStyle}`}
    variants={disabled ? {} : banner}
    initial="initial"
    animate="animate"
  >
    {/* @ts-ignore */}
    {[...title].map((letter, i) => (
      <motion.span className={`row-letter ${letterStyle}`} key={letter+i} variants={disabled ? {} : letterAni}>
        {letter}
      </motion.span>
    ))}
  </motion.span>
);

const BannerRowTop = ({ title }: { title: string }) => {
  return (
    <div className={"banner-row white"}>
      <div className="row-col">
        <AnimatedLetters title={title} />
      </div>
      <motion.div
        initial={{ opacity: 0, y: 80 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          ease: "easeInOut",
          duration: 1,
          delay: 0.4,
        }}
        className="row-col"
      >
        <span className="row-message hidden lg:inline translate-y-[20px] -translate-x-[0px] xxl:-translate-x-[100px]">Ready for a challenge</span>
      </motion.div>
    </div>
  );
};

const BannerRowBottom = ({ title, scroll1, scroll2 }: { title: string; scroll1: string; scroll2: string }) => {
  return (
    <div className={"flex flex-col-reverse xl:flex-row items-center overflow-hidden px-[54px] justify-center text-center"}>
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ ease: [0.6, 0.01, -0.05, 0.95], duration: 1, delay: 1 }}
        className="block xl:absolute mt-4 xl:mt-0 w-[80px] h-[80px] md:w-[120px] md:h-[120px] xxl:w-[160px] xxl:h-[160px] scroll"
      >
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            ease: "easeInOut",
            duration: 1,
            delay: 1.8,
          }}
        >
          {scroll1}
        </motion.span>
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            ease: "easeInOut",
            duration: 1,
            delay: 1.8,
          }}
        >
          {scroll2}
        </motion.span>
      </motion.div>
      <AnimatedLetters title={title} />
    </div>
  );
};

const BannerRowCenter = ({
  title,
  playMarquee,
}: {
  title: string;
  playMarquee: boolean;
}) => {
  return (
    <div className={`banner-row marquee  ${playMarquee && "animate"}`}>
      <motion.div
        initial={{ y: 310 }}
        animate={{ y: 0 }}
        transition={{ ease: [0.6, 0.01, -0.05, 0.9], duration: 1 }}
        className="marquee__inner"
      >
        <AnimatedLetters title={title} disabled />
        <AnimatedLetters title={title} />
        <AnimatedLetters title={title} />
        <AnimatedLetters title={title} disabled />
      </motion.div>
    </div>
  );
};

export default Banner;
