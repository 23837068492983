import { useContext } from "react";
import { LanguageContext } from "../../../context/language"

export const Footer = () => {
    const {languageFile} = useContext(LanguageContext);

    return <section aria-label="Footer" className="p-4 bg-valhalla text-white text-[1rem] flex flex-col space-y-4 items-center justify-center">
        <div className="flex flex-col space-y-2 md:flex-row md:space-y-0 md:space-x-4 items-center ">
            <a href="/datenschutz.pdf" target="_blank" rel="norefferer noopener" className="hover:underline">{languageFile.footer.privacyStatement}</a>
            <a href="/impressum.pdf" target="_blank" rel="norefferer noopener" className="hover:underline">{languageFile.footer.imprint}</a>
        </div>
        <p>
            everse.at © 2022
        </p>
    </section>
}