import { motion } from "framer-motion";
import { useContext } from "react";
import { LanguageContext } from "../../../context/language";
import { LanguageSwitch } from "../../atoms";
import './index.css';
import Sidebar from "./Sidebar";

export const Header = () => {
  const { languageFile } = useContext(LanguageContext);

  const navItemStyle = "my-0 mx-[15px] screen4k:mx-[40px] list-none whitespace-nowrap";
  return (
    <motion.div
      initial={{ opacity: 0, y: -180 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        ease: "easeInOut",
        duration: 1,
        delay: 0.6,
      }}
      className="text-[1rem] py-0 px-4 sm:px-8 lg:text-[1.8rem] h-[80px] lg:h-[156px] flex justify-center lg:px-[108px]"
    >
      <div className="w-full flex items-center justify-end xxl:!justify-between">
        <span className="font-bold text-[2.5rem] md:text-[3.6rem] logo flex-grow-[4] md:flex-grow text-center xxl:!text-start">Everse</span>
        <nav className="hidden xxl:flex items-center justify-center absolute left-[50%] -translate-x-[50%] xxl:left-[60%] xxl:-translate-x-[60%] lg:text-6 xl:text-[1.6rem] nav">
          <li className={navItemStyle}>
            <a href="#services">{languageFile.navigation.services}</a>
          </li>
          <li className={navItemStyle}>
            <a href="#projects">{languageFile.navigation.projects}</a>
          </li>
          <li className={navItemStyle}>
            <a href="#contact">{languageFile.navigation.contact}</a>
          </li>
          <li className={navItemStyle}>
            <LanguageSwitch />
          </li>
        </nav>
        <div className="text-black no-underline border-b-[2px] border-solid whitespace-nowrap text-right hidden sm:block">
          <a href="mailto:office@everse.at">{languageFile.navigation.cta}</a>
        </div>
        <div className="hamburger-menu xxl:!hidden">
          <Sidebar />
        </div>
      </div>
    </motion.div>
  );
};
