export const firebaseConfig = {
  apiKey: "AIzaSyCsmdlSzVwgEClFZXkn8x7mUTXKHbVnnz4",
  authDomain: "everse-website.firebaseapp.com",
  projectId: "everse-website",
  storageBucket: "everse-website.appspot.com",
  messagingSenderId: "902180290611",
  appId: "1:902180290611:web:32f0cd48f9dfe57e02e346",
  measurementId: "G-4KG0HZK5DQ"
};

