import { motion, useAnimation, useScroll } from "framer-motion";
import { useContext, useEffect, useLayoutEffect, useRef } from "react";
import { LanguageContext } from "../../../context/language";
import './index.css';
interface Project {
  title: string;
  img: string;
  keywords: string[];
  link: string;
}
export const Projects = () => {
  const { languageFile } = useContext(LanguageContext);

  const projects: Project[] = [
    {
      title: "HGÖ Burgenland",
      img: "/img/projects/hgoe.png",
      link: "https://hgoe-burgenland.at",
      keywords: [
        "Website",
        "App",
        "Tool",
        "WordPress",
        "Angular",
        "Node.js",
        "MongoDB",
      ],
    },
    {
      title: "Admissio",
      img: "/img/projects/admissio.png",
      link: "https://admissio.at/",
      keywords: ["Website", "App", "Tool", "Angular", "Node.js", "MongoDB"],
    },
    {
      title: "Naturjuwelgaas",
      img: "/img/projects/naturjuwelgaas.png",
      link: "https://naturjuwelgaas.at",
      keywords: ["Website", "WordPress", "Smoobu", "Booking.com", "Airbnb"],
    },
  ];

  const ref = useRef(null);
  const { scrollXProgress } = useScroll({ container: ref });
  return (
    <section
      id="projects"
      aria-label={languageFile.projects.sectionTitle}
      className="w-full h-fit text-black px-10 py-10 flex flex-col xxl:flex-row justify-between items-center projects"
    >
      <div className="w-full xxl:w-[40%] flex flex-col md:flex-row space-y-4 md:space-y-0 justify-center items-center projects-description">
        <h2 className="text-center rotate-0 md:rotate-[270deg] text-[2rem] sm:text-[4rem] md:text-[8rem] projects-title">
          {languageFile.projects.sectionTitle}
        </h2>
        <div className="projects-keywords">
          <h3 className="text-[1.rem] sm:text-[2rem] md:text-[3rem]">{languageFile.projects.technologies}</h3>
          <Grid />
        </div>
      </div>
      <div className="w-full overflow-hidden xxl:w-[60%] h-[60vh] min-h-[500px] mt-16 pt-10 xxl:pt-0 xxl:mt-0 projects-gallery">
        <svg id="progress" className="absolute -top-[50px] left-[38%] md:left-[45%] -translate-x-[50%] xxl:top-[38%] xxl:left-[5%] xxl:-translate-x-0 z-[1000]" width="100" height="100" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="30" pathLength="1" className="bg" />
          <motion.circle
            cx="50"
            cy="50"
            r="30"
            pathLength="1"
            className="indicator"
            style={{ pathLength: scrollXProgress }}
          />
        </svg>
        <ul ref={ref}>
          {projects.map((project) => (
            <li key={project.title}>
              <div className="project-heading">
                <h4 className="text-[1rem] md:text-[1.5rem]">
                  <a className="hover:underline after:content-['\2197'] font-awesome" href={project.link} target="_blank" rel="norefferer noopener">{project.title}</a>
                </h4>
                <div className="project-keywords">
                  {project.keywords.map(keyword => <span key={keyword}>{keyword}</span>)}
                </div>
              </div>
              <img src={project.img} />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

function Grid({ delayPerPixel = 0.0008 }) {
  const originOffset = useRef({ top: 0, left: 0 });
  const controls = useAnimation();
  const projectKeywords: string[] = [
    "React",
    "Next.js",
    "Angular",
    "Node.js",
    "MongoDB",
    "Microsoft SQL",
    "Three.js",
    "Framer Motion",
    "Java Spring Boot",
  ];
  useEffect(() => {
    controls.start("visible");
  }, []);

  return (
    // We set variants to be an object to force variant propagation - this is a bug
    // with variants and useAnimation()
    // https://github.com/framer/motion/issues/191
    <motion.div initial="hidden" animate={controls} variants={{}}>
      {projectKeywords.map((keyword, i) => (
        <GridItem
          key={i}
          i={i}
          originIndex={26}
          delayPerPixel={delayPerPixel}
          originOffset={originOffset}
          name={keyword}
        />
      ))}
    </motion.div>
  );
}
//   @ts-ignore
function GridItem({ name, delayPerPixel, i, originIndex, originOffset }) {
  const delayRef = useRef(0);
  const offset = useRef({ top: 0, left: 0 });
  const ref = useRef();

  // The measurement for all elements happens in the layoutEffect cycle
  // This ensures that when we calculate distance in the effect cycle
  // all elements have already been measured
  useLayoutEffect(() => {
    // @ts-ignore
    const element = ref.current;
    if (!element) return;
    offset.current = {
      // @ts-ignore
      top: element.offsetTop,
      // @ts-ignore
      left: element.offsetLeft,
    };

    if (i === originIndex) {
      originOffset.current = offset.current;
    }
  }, [delayPerPixel]);

  useEffect(() => {
    const dx = Math.abs(offset.current.left - originOffset.current.left);
    const dy = Math.abs(offset.current.top - originOffset.current.top);
    const d = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
    delayRef.current = d * delayPerPixel;
  }, [delayPerPixel]);

  // @ts-ignore
  return (
    <motion.div
      className="grid-item p-[5px] md:p-[10px]"
      //   @ts-ignore
      ref={ref}
      variants={itemVariants}
      custom={delayRef}
    >
      <span>{name}</span>
    </motion.div>
  );
}

const itemVariants = {
  hidden: {
    opacity: 0,
    scale: 0.5,
  },
  visible: (delayRef: { current: any }) => ({
    opacity: 1,
    scale: 1,
    transition: { delay: delayRef.current },
  }),
};
