import { useContext } from "react";
import { LanguageContext } from "../../../context/language";
import { PersonDescription } from "../../atoms";
import DeveloperTeam from "../Service/SVGs/developer-team";
import './index.css';

export const Contact = () => {
  const { languageFile } = useContext(LanguageContext);

  return (
    <section
      id="contact"
      aria-label={languageFile.contact.ariaLabel}
      className="w-full h-fit text-valhalla flex flex-col-reverse xxl:flex-row justify-between items-center py-12"
    >
      <div className="w-full xxl:w-[50%] flex flex-col space-y-8 md:space-y-0 md:flex-row justify-evenly align-center">
        <PersonDescription
          name="Jan Hoppel"
          linkedIn="https://www.linkedin.com/in/jan-hoppel-4b4a35214/"
          imgSrc="/img/team/jan.jpg"
          techStack={["React", "Angular", "Node.js", "Three.js", "Framer Motion"]}
          shortIntro={languageFile.about.jan.shortIntro}
          secondaryImgSrc="/img/team/turtle2.jpg"
        />
        <PersonDescription
          name="Petronel Liciu"
          imgSrc="/img/team/petro.JPG"
          linkedIn="https://www.linkedin.com/in/petronel-liciu-79a1ba24a/"
          techStack={["React", "Angular", "Node.js", "React Native", "Java Springboot"]}
          shortIntro={languageFile.about.petro.shortIntro}
          secondaryImgSrc="/img/team/panda.jpg"

        />
      </div>
      <div className="h-fit flex justify-center xxl:justify-start items-center flex-col w-full xxl:w-[50%] py-10 xxl:pr-10 mb-10 xxl:mb-0">
        <div className="w-full text-[2rem] md:text-[3rem] xxl:text-[5rem] px-8 xxl:px-0 text-center">
          <span>{languageFile.contact.text}</span>
        </div>
        <div className="flex flex-col-reverse space-y-8 xxl:space-y-0 xxl:flex-row justify-center md:justify-evenly items-center w-full">
          <a
            href="mailto:office@everse.at"
            className="text-[1rem] md:text-[1.5rem] lg:text-[2.5rem] bg-valhalla text-white hover:scale-110 p-2 rounded-lg transition duration-200 ease-out hover:ease-in whitespace-nowrap cta-mail"
          >
            {languageFile.contact.linkText}
          </a>
          <div className="flex justify-center items-center h-[10vh] xxl:h-[20vh] mb-4 xxl:mb-0 envelope">
            <DeveloperTeam />
          </div>
        </div>
      </div>
    </section>
  );
};
