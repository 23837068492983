import { useContext } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../../context/language";
import { LanguageSwitch } from "../../atoms";

export const ErrorPage = () => {
    const { languageFile } = useContext(LanguageContext);
    return (
        <div id="error-page" className="flex flex-col space-y-8 items-center justify-center h-[100vh] w-full">
          <h1 className="text-[1.5rem] md:text-[2.5rem] font-bold">Oops</h1>
          <p>{languageFile.error.message}</p>
          <Link to="/" className="!underline">
            {languageFile.error.backToHomepage}
          </Link>
          <LanguageSwitch />
        </div>
      );
}