import { motion, useCycle } from "framer-motion";
import { useRef } from "react";
import { MenuToggle } from "./MenuToggle";
import { Navigation } from "./SidebarNav";

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(30px at 40px 40px)",
    transition: {
      delay: 0.2,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

const Sidebar = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const bodyRef = useRef(document.getElementById("body"));
  const height = 1000;
  const handleToggle = () => {
    if (bodyRef.current) {
      if (isOpen) {
        bodyRef.current.classList.remove("noscroll");
      } else {
        bodyRef.current.classList.add("noscroll");
      }
    }
    toggleOpen();
  };
  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={height}
      ref={containerRef}
      className="sidebar"
    >
      <motion.div className="background" variants={sidebar} />
      <Navigation toggle={() => handleToggle()} isOpen={isOpen}/>
      <MenuToggle toggle={() => handleToggle()} />
    </motion.nav>
  );
};

export default Sidebar;
