import { useState } from "react";
import "./App.css";
import { motion, AnimatePresence } from "framer-motion";
import { ImageBlock } from "./components/atoms/ImageBlock";
import Hero from "./components/sections/Hero/Hero";
import './App.css';
import { Header, Service, Contact, Projects, Footer } from './components';

const transitionImageStyle = 'absolute w-full h-full top-0 left-0 flex items-center justify-center transition-image';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <AnimatePresence>
      {isLoading ? (
        <motion.div key="loader">
          <Loader setLoading={setIsLoading} />
        </motion.div>
      ) : (
        <>
          <Header />
          <Hero />
          {!isLoading && (
            <div className={`absolute block top-[80px] lg:top-[130px] xxl:top-[160px] left-0 h-[150px] md:h-[250px] xxl:h-[300px] w-full -z-[100] overflow-hidden py-0 px-[3%]`}>
              <motion.img
                transition={{ ease: [0.6, 0.01, -0.05, 0.9], duration: 1.6 }}
                src="/main.jpg"
                layoutId="transition-image"
                className="w-full max-w-full flex"
              />
            </div>
          )}
          <Service />
          <Projects />
          <Contact />
          <Footer />
        </>
      )}
    </AnimatePresence>
  );
}

const Loader = ({ setLoading }: { setLoading: Function }) => {
  const imgs = ["/cloud-network.svg", "/developer-team.svg", "/software-engineer.svg", "/website-maintenance.svg"];
  const container = {
    show: {
      transition: {
        staggerChildren: 0.35,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 200 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 1.6,
      },
    },
    exit: {
      opacity: 0,
      y: -200,
      transition: {
        ease: "easeInOut",
        duration: 0.8,
      },
    },
  };

  const itemMain = {
    hidden: { opacity: 0, y: 200 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 1,
      },
    },
  };
  return (
    <motion.div
      className="loader"
      variants={container}
      initial="hidden"
      animate="show"
      exit="exit"
      onAnimationComplete={() => setLoading(false)}
    >
      <motion.div className={transitionImageStyle} variants={itemMain}>
        <motion.img layoutId="transition-image" className="w-[800px] flex" src="/main.jpg" />
      </motion.div>
      {/* {imgs.map((img, idx)=> <ImageBlock id={`img-${idx}`} key={img} src={img} variants={item} />)} */}
    </motion.div>
  );
};

export default App;
