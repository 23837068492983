import { useContext } from "react";
import { LanguageContext } from "../../../context/language";

interface PersonDescriptionProps {
  name: string;
  imgSrc: string;
  techStack: string[];
  shortIntro: string;
  linkedIn: string;
  secondaryImgSrc: string;
}
export const PersonDescription = ({
  name,
  imgSrc,
  techStack,
  shortIntro,
  linkedIn,
  secondaryImgSrc,
  ...props
}: PersonDescriptionProps) => {
  const { languageFile } = useContext(LanguageContext);
  return (
    <div className="contact-card m-auto">
      <img className="secondary-img" src={secondaryImgSrc} />
      <div className="main-img-wrapper">
        <span className="main-img-wrapper-span">
          <img src={imgSrc} alt={name}/>
        </span>
      </div>
      <div className="info-wrapper">
        <div className="info">
          <h2>{name}</h2>
          <p>Full-stack Developer</p>
        </div>
        <div className="additional">
          {techStack.map((stack) => (
            <div className="item" key={stack}>
              <span>{stack}</span>
            </div>
          ))}
        </div>
        <button>
          <a href={linkedIn} rel="noopener noreferrer" target="_blank">
            LinkedIn
          </a>
        </button>
      </div>
    </div>
  );
};
