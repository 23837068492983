import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { LanguageProvider } from './context/language';
import { initializeApp } from "firebase/app";
import { firebaseConfig } from './firebase.config';

import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";

import { ErrorPage } from './components';
import ReactGA from "react-ga4";

ReactGA.initialize("G-1N85SG708R");
ReactGA.send("pageview");
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

initializeApp(firebaseConfig);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />
  }
]);

root.render(
  <React.StrictMode>
    <LanguageProvider>
      <RouterProvider router={router}/>
    </LanguageProvider>
  </React.StrictMode>
);
