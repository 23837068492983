import * as React from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";
import { LanguageContext } from "../../../context/language";
import { useContext } from "react";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

export const Navigation = ({ toggle, isOpen }: { toggle: any, isOpen: boolean }) => {
  const handleNav = (id: string) => {
    toggle();
    document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
  };
  const languageFile = useContext(LanguageContext).languageFile;
  return (
    <motion.ul variants={variants} className={`${isOpen ? '' : 'hidden'}`}>
      <MenuItem
        name={languageFile.navigation.services}
        i={0}
        onClickFunction={() => handleNav("service")}
      />
      <MenuItem
        name={languageFile.navigation.projects}
        i={1}
        onClickFunction={() => handleNav("projects")}
      />
      <MenuItem
        name={languageFile.navigation.contact}
        i={2}
        onClickFunction={() => handleNav("contact")}
      />
      <a target="_blank" rel="nofollow noopener noreferrer" href="mailto:office@everse.at">
        <MenuItem
          name={languageFile.navigation.cta}
          i={3}
          onClickFunction={() => {}}
        />
      </a>
    </motion.ul>
  );
};

const itemIds = [0, 1, 2, 3, 4];
