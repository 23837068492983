import { motion } from "framer-motion";
import { useContext } from "react"
import { LanguageContext } from "../../../context/language"
import './index.css';

const spring = {
    type: "spring",
    stiffness: 700,
    damping: 30
};

export const LanguageSwitch = ({...props}) =>{
    const {isEN, toggleLanguage, languageFile} = useContext(LanguageContext);

    return (
        <button 
            onClick={toggleLanguage} 
            className={`language-switch flex space-x-4 px-4 py-2 border rounded-[5rem] text-[1rem] ${props.className}`}
            aria-label={languageFile.languageSwitch.ariaLabel}>
            <span className="rounded-[5rem] px-2">DE {!isEN && <motion.div className="language-indicator" layoutId="language-indicator" transition={spring}/>}</span>
            <span className="rounded-[5rem] px-2">EN {isEN && <motion.div className="language-indicator" layoutId="language-indicator" transition={spring}/>}</span>
        </button>
    )
}