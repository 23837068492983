import { createContext, ReactChild, useEffect, useState } from 'react';
import DE from '../texts/DE.json';
import EN from '../texts/EN.json';

export interface ILanguageContext {
    languageFile: any;
    isEN: boolean;
    toggleLanguage(): void;
}

export const LanguageContext = createContext<ILanguageContext>({
    languageFile: DE,
    isEN: false,
    toggleLanguage: () => { }
});

export const LanguageProvider = ({ children }: { children: ReactChild }) => {
    const [languageFile, setLanguageFile] = useState(DE);
    const [isEN, setEN] = useState(false);

    useEffect(() => {
        document.documentElement.setAttribute('lang', isEN ? 'en' : 'de');
    }, [isEN]);

    const toggleLanguage = () => {
        if (isEN) {
            setEN(false);
            setLanguageFile(DE);
        } else {
            setEN(true);
            setLanguageFile(EN);
        }
    }

    return (
        <LanguageContext.Provider value={{ languageFile, isEN, toggleLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
}
