import { motion } from "framer-motion";

const DeveloperTeam = () => {
  const transition = { duration: 4, yoyo: Infinity, ease: "easeInOut" };

  const draw = {
    hidden: { pathLength: 0, opacity: 0},
    visible: (i: number) => {
      const delay = 1 + i;
      return {
        pathLength: 1,
        opacity: 1,
        transition: {
          pathLength: { delay, duration: 4,ease: "easeInOut"},
          opacity: { delay, duration: 4,ease: "easeInOut"},
        },
      };
    },
  };
  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      className='max-h-full h-full'
    >
        <motion.path
          //  d="M 239 17 C 142 17 48.5 103 48.5 213.5 C 48.5 324 126 408 244 408 C 362 408 412 319 412 213.5 C 412 108 334 68.5 244 68.5 C 154 68.5 102.68 135.079 99 213.5 C 95.32 291.921 157 350 231 345.5 C 305 341 357.5 290 357.5 219.5 C 357.5 149 314 121 244 121 C 174 121 151.5 167 151.5 213.5 C 151.5 260 176 286.5 224.5 286.5 C 273 286.5 296.5 253 296.5 218.5 C 296.5 184 270 177 244 177 C 218 177 197 198 197 218.5 C 197 239 206 250.5 225.5 250.5 C 245 250.5 253 242 253 218.5"
           fill="transparent"
           strokeWidth="1"
           stroke="rgba(0, 0, 0, 0.69)"
           strokeLinecap="round"
           variants={draw}
           initial="hidden"
           animate="visible"
           custom={1}
          //  transition={transition}
          d="M31.59 11.19 27 7.83V5a1 1 0 0 0-1-1h-4.22L16.59.19a1 1 0 0 0-1.18 0L10.22 4H6a1 1 0 0 0-1 1v2.83L.41 11.19A1 1 0 0 0 0 12v17a3 3 0 0 0 3 3h26a3 3 0 0 0 3-3V12a1 1 0 0 0-.41-.81zM27 10.31l2.2 1.61-2.2 1.31zM16 2.24 18.4 4h-4.8zM7 6h18v8.43l-9 5.4-9-5.4zm-2 4.31v2.93l-2.2-1.32zM2 28.59V13.77l9.26 5.56zM3.41 30 13 20.38l2.46 1.47a1 1 0 0 0 1 0L19 20.38 28.59 30zM30 28.59l-9.26-9.26L30 13.77z"
        />
        <motion.path
          fill="transparent"
          strokeWidth="1"
          stroke="rgba(0, 0, 0, 0.69)"
          strokeLinecap="round"
          variants={draw}
           initial="hidden"
           animate="visible"
           custom={2}
          // transition={transition}
          d="M10 9h12v2H10zM12 13h8v2h-8z"
        />
    </motion.svg>
  );
};

export default DeveloperTeam;
