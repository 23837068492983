import { AnimatePresence, motion, useUnmountEffect } from "framer-motion";  
import { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../../context/language";
import CloudNetwork from "./SVGs/cloud-network";
import SoftwareEngineer from "./SVGs/software-engineer";
import WebsiteMaintenance from "./SVGs/website-maintenance";
import { GrVmMaintenance } from "react-icons/gr";
import { FaTools } from "react-icons/fa";
import { MdOutlineWeb } from "react-icons/md";
import './index.css';

interface Service {
  svg: any;
  title: string;
  paragraph: string;
  subtext?: string;
  titleShort?: string;
  icon?: any;
}
export const Service = () => {
  const { languageFile } = useContext(LanguageContext);
  const services: Service[] = [
    {
      svg: SoftwareEngineer,
      title: languageFile.service.appdev.title,
      icon: MdOutlineWeb,
      paragraph: languageFile.service.appdev.content
    },
    {
      svg: WebsiteMaintenance,
      titleShort: languageFile.service.maintenance.title,
      title: languageFile.service.maintenance.heading,
      icon: GrVmMaintenance,
      paragraph: languageFile.service.maintenance.content
    },
    {
      svg: CloudNetwork,
      title: languageFile.service.tools.title,
      icon: FaTools,
      paragraph: languageFile.service.tools.content
    },
  ];
  const [selected, setSelected] = useState(0);

  const tabRefs: HTMLButtonElement[] = [];

  const spring = {
    type: "spring",
    stiffness: 300,
    damping: 30,
  };

  const focusTab = (idx: number) =>{
    if(idx < 0){
      tabRefs[tabRefs.length-1]?.focus();
    }
    if(idx >= tabRefs.length){
      tabRefs[0]?.focus();
    }
    tabRefs[idx]?.focus();
  }

  useEffect(()=>{
    tabRefs.forEach((tab,i) =>{
      tab.addEventListener('keydown', (event)=>{
        let prevDef = false;
        switch(event.key){
          case 'ArrowLeft':
          case 'Left':
            focusTab(i-1);
            prevDef = true;
            break;
          case 'ArrowRight':
          case 'Right':
            focusTab(i+1);
            prevDef = true;
            break;
        }
        if(prevDef){
          event.preventDefault();
          event.stopPropagation();
        }
      });
    })
  }, [tabRefs]);
  return (
    <section
      id="service"
      aria-label={languageFile.service.title}
      className="w-full h-fit text-black service"
    >
      <div className="flex flex-col xxl:space-y-0 xxl:flex-row service-wrapper">
        <div className="w-full xxl:w-[50%]">
          <h2 id="servicesHeading" className="text-[1.75rem] md:text-[3rem] lg:text-[5rem] py-8 lg:py-14 text-center">{languageFile.service.title}</h2>
          <div role="tablist" className="tablist" aria-labelledby="servicesHeading">
            {services.map((service, idx) => (
                <button 
                  key={service.title}
                  className={`flex items-center -outline-offset-1 serviceTab ${idx === selected ? "selected" : ""}`}
                  onClick={() => setSelected(idx)}
                  onTouchStart= {() => setSelected(idx)}
                  aria-label={service.titleShort || service.title}
                  type="button"
                  role="tab"
                  aria-controls={service.title}
                  aria-selected={idx === selected}
                  tabIndex={idx === 0 ? 0 : -1}
                  ref={ref => ref && tabRefs.push(ref)}>
                  {service.icon && (
                    <span className="icon px-2">{service.icon()}</span>
                  )}
                  <span className="hidden md:block">{service.titleShort || service.title}</span>
                  {idx === selected ? (
                    <motion.div
                      className="underline"
                      layoutId="underline"
                      transition={spring}
                    />
                    ) : null}
                </button>
            ))}
          </div>
          <div className="w-full" role="tabpanel" id={services[selected].title} aria-label={services[selected].title}>
            <div className="flex justify-center">
              <h2 className="text-[1.5rem] md:text-[2rem] text-center py-4">{services[selected].title}</h2>
            </div>
            <div className="flex text-center">
              <p className="py-4 text-[1rem] md:text-[1.5rem]">
                {services[selected].paragraph}
              </p>
            </div>
          </div>
        </div>
        <div className="flex space-x-2 w-full md:w-[50%] flex-wrap justify-center svg-wrapper" aria-hidden>
          <AnimatePresence>{services[selected].svg()}</AnimatePresence>
        </div>
      </div>
    </section>
  );
};
